$(document).ready(function ($) {
    var url = new URL(window.location);
    sv = url.searchParams.get("sv");
    sg = url.searchParams.get("sg");
    var tout = 8000;

    if (sv != null) {
        $('html, body').animate({
            scrollTop: $("#video-clube").offset().top - $(window).height() / 4
        }, 2000);       
        //document.getElementById("video-promo-clube").click();

        
        
        //$('video').get(0).play();

        tout=50000;
    }

    if (sg != null) {
        setTimeout(() => {$('#overlay').modal('show');}, tout);
    }

    $('.banner_btn').click(function(e){
        e.preventDefault();
        var target = $($(this).attr('href'));
        if(target.length){
          var scrollTo = target.offset().top;
          $('body, html').animate({scrollTop: scrollTo+'px'}, 800);
        }
    });
});

